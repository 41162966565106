const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: '646c8692e46f9501337dcd92',
    enableExperimentalBlocks: false,
    authMode: ['providers'],
    authOAuthProviders: [
      [
        'WSSOKeycloak',
        [
          {
            realm: 'LaPoste',
            clientId: 'vi-la-poste',
            buttonText: 'Connexion La Poste',
            autoLogin: true,
          },
        ],
      ],
    ],
    nuxt: {
      head: {
        title: "POPS la radio",
        meta: [
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content:
              'width=device-width, initial-scale=1, minimal-ui, shrink-to-fit=no',
          },
          {
            hid: 'description',
            name: 'description',
            content: `La radio des postières et postiers de la BSCC`,
          },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            hid: 'robots',
            name: 'robots',
            content: 'noindex,nofollow',
          },
          {
            hid: 'ogSiteName',
            name: 'og:site_name',
            content: "POPS la radio",
          },
          {
            hid: 'ogTitle',
            name: 'og:title',
            content: 'POPS',
          },
          {
            hid: 'ogDescription',
            name: 'og:description',
            content: `La radio des postières et postiers de la BSCC`,
          },
          {
            hid: 'ogType',
            name: 'og:type',
            content: 'website',
          },
        ],
      },
    },
    drawerNavigation: () => [
      {
        type: 'link',
        icon: 'mdi-cog-outline',
        value: '/settings',
        title: (ctx) => ctx.$t('glob_sidebar_settings'),
      },
      {
        icon: 'mdi-information-outline',
        value: 'info',
        title: (ctx) => ctx.$t('glob_sidebar_moreabout'),
        noAction: true,
        disableAutoClose: true,
        items: [
          {
            type: 'link',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_legals`),
            value: '/data/legal-notice',
          },
          {
            type: 'link',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_terms`),
            value: '/data/terms-of-service',
          },
          {
            type: 'link',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_data`),
            value: '/data/personal-data',
          },
          {
            type: 'link',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_concourse`),
            value: '/data/concours',
          },
          {
            type: 'method',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_versions`),
            value: 'versions',
            onClick: (context) => {
              context.dialogVersions = true
            },
          },
        ],
      },
      {
        type: 'link',
        icon: 'mdi-help-circle-outline',
        title: (ctx) => ctx.$t(`glob_sidebar_support`),
        value: '/support',
      },
      {
        condition(context) {
          return context.$spoke.site.$data('optAuthentication') === true
        },
        type: 'method',
        icon: 'mdi-exit-to-app',
        value: 'logout',
        title: (ctx) => ctx.$t('glob_sidebar_exit'),
        onClick: async (context) => {
          await context.$store.dispatch('auth/logout')
        },
      },
    ],
    enableSplashScreen: 'wavy',
    showCardDescription: true,
    metadatas: {
      name: 'POPS la Radio',
      colorPrimary: '#FFCC00',
      colorAccent: '#003DA5',
      supportEmailAddress: 'support@pops-laradio.fr',
    },
    analytics: {
      matomo: '37',
    },
    useShell: [
      'bb-default',
      {
        mode: 'list',
      },
    ],
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
