var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-block__pre-heading",class:{ 'ml-4 mr-4 mt-3': _vm.$voicer.isMobile }},[(_vm.isHomeActive)?_c('v-container',{staticClass:"pa-0"},[_c('v-sheet',{ref:"sheet",staticClass:"pa-2",class:{
        'v-sheet__desktop': _vm.$voicer.isDesktopOrTablet,
        'ma-3 mb-4': _vm.$voicer.isDesktopOrTablet,
      },attrs:{"color":"#C9E4F1"}},[_c('div',{staticClass:"text-alternate",class:{
          'body-2 font-weight-normal': _vm.$voicer.isDesktopOrTablet,
          'body-2': _vm.$voicer.isMobile,
        }},[_c('div',{staticClass:"pl-2 pr-2 pt-1"},[_vm._v("\n          Vous souhaitez partager une actu, poser une question, faire une\n          dédicace à un de vos collègues ? Contactez nous au\n          "),_c('a',{attrs:{"href":"tel:+33186860686"}},[_vm._v("01 86 86 06 86")]),_vm._v(" ou par mail sur\n          "),_c('a',{attrs:{"href":"mailto:contact@pops-laradio.fr"}},[_vm._v("contact@pops-laradio.fr")])]),_vm._v(" "),_c('v-btn',{ref:"btn",staticClass:"ma-2 white--text",attrs:{"href":"mailto:contact@pops-laradio.fr","color":"#164194","rounded":"","depressed":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#ffffff"}},[_vm._v("\n            mdi-comment-processing\n          ")]),_vm._v("\n          Nous écrire\n        ")],1)],1)])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }